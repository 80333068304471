import React from 'react'
import { graphql } from 'gatsby'
import Event from '../../containers/Event/Event'
import Layout from '../../components/Layout/Layout'
import { StyledEventPage } from './styles'
import SEO from '../../components/Seo/Seo'

const EventPage = ({ data }) => {
  const {
    event: { name, poster, posterVertical },
  } = data
  return (
    <Layout>
      <SEO title={name} />
      <StyledEventPage>
        <Event poster={poster} posterVertical={posterVertical} />
      </StyledEventPage>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    event(id__normalized: { eq: $slug }) {
      id__normalized
      rsvpLeft
      rsvpLimit
      rsvpList
      startTime {
        utc
        tz
      }
      venue
      name
      poster {
        local {
          childImageSharp {
            fluid(maxWidth: 3072, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      posterVertical {
        local {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 60) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default EventPage
