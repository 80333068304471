/* eslint-disable import/no-extraneous-dependencies */
import { useLocation } from '@reach/router'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getEvent, dropEvent } from './actions'

export default () => {
  const { pathname } = useLocation()
  const id = pathname.slice(1)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getEvent(id))

    return () => {
      dispatch(dropEvent())
    }
  }, [id, dispatch])
}
