import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useEventRequest from './useEventRequest'
import EventView from '../../components/EventView/EventView'
// import { getUser } from '../Profile/actions'
import { registerForEvent } from './actions'
// import windowOpener from '../../helpers/windowOpener'
import Modal from '../../components/shared/Modal/Modal'
import useModal from '../../hooks/useModal'

const messages = {
  login: 'LOGIN',
  close: 'CLOSE',
  registerForEvent: 'REGISTER_FOR_EVENT',
}

const Event = ({ poster, posterVertical }) => {
  useEventRequest()
  const { isOpen, closeModal, openModal } = useModal()

  const {
    event: { isLoading, entity },
    profile: {
      isAuthenticated,
      entity: { id: userId },
    },
  } = useSelector(({ event, profile }) => ({
    event,
    profile,
  }))

  const isRegisteredForEvent =
    entity.rsvpList && entity.rsvpList.includes(userId)

  const dispatch = useDispatch()

  const handleRegisterForEvent = (event, userID) => {
    openModal()
    if (isRegisteredForEvent) {
      return
    }
    const eventId = event.id
    dispatch(registerForEvent({ eventId, userId: userID }))
    postMessage(messages.registerForEvent, event)
  }

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const action = urlParams.get('modal')

    if (action === 'true') {
      openModal()

      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
      window.history.pushState({ path: newurl }, '', newurl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const onTelegramAuth = (user) => {
  //   console.log(user)
  //   dispatch(getUser(user))
  //   postMessage(messages.login, user)
  //   handleRegisterForEvent(entity, user.id)
  // }

  // const handleOpen = windowOpener(onTelegramAuth)
  const handleOpen = () => {
    const loginUrl = process.env.GATSBY_LOGIN_URL
    window.location.href = loginUrl
  }

  const handleClick = (e) => {
    e.preventDefault()

    if (!isAuthenticated) {
      handleOpen(e)
    } else {
      handleRegisterForEvent(entity, userId)
    }
  }

  return (
    <>
      <EventView
        isLoading={isLoading}
        isRegisteredForEvent={isRegisteredForEvent}
        event={entity}
        poster={poster}
        posterVertical={posterVertical}
        onClick={handleClick}
      />

      <Modal open={isOpen} onClose={closeModal}>
        <p>Для продовження будь ласка перейдіть у ваш Telegram месенджер</p>
      </Modal>
    </>
  )
}

export default Event
